import React from "react"
import { InView } from 'react-intersection-observer'
import { motion } from "framer-motion"

export default function Anisection({children, props}) {
    return (
    <InView threshold={0}>
        {({ref,inView}) => (
      <motion.div
        ref={ref}
        initial={{opacity: 0}}
        animate={inView ? { opacity: 1 } : {opacity: 0}}
        transition={{duration: 0.5}}
        >
        {children}
        </motion.div>
      )}
    </InView>
    )
}