import React from 'react'
import styled from "@emotion/styled"
import { IconContext } from "react-icons";
import { FiArrowUpRight } from 'react-icons/fi'

const Title = styled.h2`
  font-family: 'Barlow Semi Condensed', sans-serif; 
  font-weight: 500;
  font-size: 1.15rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  white-space: nowrap;
  position: absolute; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255,255,255,0.9);
  z-index: 10;
  background-color: rgba(0,0,0,0.5);
  padding: 4px 6px 0 10px;
  transition: all 0.3s ease;
`

export default function Slidetitle(props) {
    return (
        <Title>{props.title} <IconContext.Provider value={{ style: { verticalAlign: 'middle', marginBottom: '3px' }, size: '1.33em' }}><FiArrowUpRight /></IconContext.Provider></Title>
    )
}