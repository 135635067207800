import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from "@emotion/styled"

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import useDimensions from "react-cool-dimensions"
import { ResizeObserver } from "@juggle/resize-observer"
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { IconContext } from "react-icons"
import { FiChevronLeft } from 'react-icons/fi'
import { FiChevronRight } from 'react-icons/fi'

import { Seo } from "../components/seo"
import Mainnav from "../components/mainnav"
import Logo from "../components/logo"
import Anisection from '../components/anisection'
import Aphorism from '../components/aphorism'
import Slidetitle from "../components/slidetitle"

const _ = require("lodash")

const Mainwrap = styled.main`
section.normal {
        padding: 10vh 0;
        width: 100vw;
        background-attachment: fixed;
        background-size: cover;
        background-position-x: center;
  > div {
    width: 90vw;
    margin: 0 auto;
    > div > div {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      @media (max-width: 599px) {
        display: block;
      }
      > div {
        position: relative;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        figure.iiz {
          overflow: hidden;
          transition: background 0.3s ease;
          img {
            height: inherit;
            width: inherit;
          }
          .iiz__zoom-img--visible {
            cursor: pointer;
          }
          &:hover {
            background: rgba(0,0,0,0.4);
          }
        }
        &.zoom {
          overflow: hidden;
        }
        @media (max-width: 599px) {
          height: 90vw;
          width: 90vw;
        }
      }
    }
  }
  button {
    background: transparent;
    border: 0;
    padding: 0;
    color: rgba(255,255,255,.66);
    position: absolute;
    z-index: 7;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    &:hover {
      cursor: pointer;
      color: rgba(255,255,255,1);
    }
  }
  .slide a img {
    transition: all 0.3s ease;
  }
  .slide a:hover img {
    filter: brightness(33%);
  }
  .video a {
    transition: all 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }
}

`
const Inquiries = styled.section`
width: 100vw;
padding: 0 !important;
.simplebar-scrollbar::before {
    background-color: rgba(255,255,255,0.33);
}
> div {
    height: 85vh;
    min-width: 100vw;
    padding: 0 5vw !important;
    box-sizing: border-box;
    background-color: #000;
}
section {
    display: flex;
    align-items: top;
    flex-wrap: nowrap;
    padding: 0;
    width: inherit;
    margin-top: 22.5vh;
}
section > div {
    margin-right: 5vw;
    flex: 0 0 auto;
    background-color: rgba(255,255,255,0.1);
    transition: all 0.3s ease;
    &:hover {
        background-color: rgba(255,255,255,0.9);
    }
}
section > div:last-of-type {
    margin-right: 0;
}
section > div a {
  padding: 20px;
  display: block;
  color: rgba(255,255,255,0.66);
    transition: all 0.3s ease;
    &:hover {
        color: #000;
    }
}
section > div a > div {
    position: relative;
  }
  .gatsby-image-wrapper {
    left: 50%;
    transform: translateX(-50%);
  }
  img {
      padding: 0 !important;
      height: 30vh;
      width: 30vh;
      @media (max-width: 599px) {
        height: 27.5vh;
        width: 27.5vh;
      }
  }
  h4 {
    margin-bottom: 0;
    margin-top: 3vh;
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
    width: 30vh;
    font-family: courier;
    text-transform: uppercase;
    line-height: 1.25em;
    letter-spacing: .5px;
    @media (max-width: 599px) {
      width: 27.5vh;
    }
}  
`
const CarouselWrap = styled.div`
  .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
  }
`
export const query = graphql`
{
  sanityHomepage {
    title
    section {
      ... on SanityPage {
        title
        id
        page {
          id
          title
          ledeImage {
            asset {
              gatsbyImageData
            }
          }
        }
      }
      ... on SanityHomeSection {
        _key
        _type
        title
        bgImage {
          asset {
            url
            gatsbyImageData
          }
        }
        item {
          ... on SanityAphorism {
            _key
            _type
            height
            size
            text
            width
            x
            y
          }
          ... on SanityVideo {
            _key
            _type
            video {
              asset {
                url
              }
            }
            link
            width
            height
            title
            x
            y
            videoFile {
              asset {
                playbackId
                assetId
              }
            }
          }
          ... on SanityZoomImage {
            _key
            _type
            height
            title
            width
            x
            y
            image {
              asset {
                url
              }
            }
            siteLink {
              title
            }
          }
          ... on SanityCarousel {
            _key
            _type
            title
            slides {
              link {
                _id
                title
                id
              }
              image {
                asset {
                  gatsbyImageData(aspectRatio: 1)
                }
              }
            }
            height
            width
            x
            y
          }
        }
      }
    }
  }
}
`

const IndexPage = ({ data }) => {
  const { observe, width } = useDimensions({
    useBorderBoxSize: true,
    polyfill: ResizeObserver,
  })

  const rowHeight = (width > 699) ? (width/10) : width

  if (!data) return null
  const home = data.sanityHomepage
  
  return (
    <>
    <Seo />
    <Logo />
    <Mainnav />
    <Mainwrap>
      {home.section.map(sec =>
      <>
        {sec._key ? 
        <section key={sec._key} className="normal" style={{backgroundImage: "url("+sec.bgImage.asset.url+")", minHeight: `100vh`}}>
          <div ref={observe}>
          <Anisection>
            <div className="normal" style={{ gridAutoRows: rowHeight }}>
            {sec.item.map(block => 
              <>
              {block.text &&
              <Aphorism title={block.text} size={block.size} style={{ gridRow: ((block.y + 1) + "/" + (Number(block.y) + Number(block.height) + 1)), gridColumn:((block.x + 1) + "/" + (Number(block.x) + Number(block.width) + 1)) }}  />
              }
              {block.video &&
              <div style={{ gridRow: ((block.y + 1) + "/" + (Number(block.y) + Number(block.height) + 1)), gridColumn:((block.x + 1) + "/" + (Number(block.x) + Number(block.width) + 1)) }} className="video">
                {block.link ?
                <a href={block.link}><img src={block.video.asset.url} alt={block.title} /></a>
                :
                <img src={block.video.asset.url}  alt={block.title} />
                }
              </div>
              }
              {block.image &&
              <div className="zoom" style={{ gridRow: ((block.y + 1) + "/" + (Number(block.y) + Number(block.height) + 1)), gridColumn:((block.x + 1) + "/" + (Number(block.x) + Number(block.width) + 1)) }}>
                { block.siteLink ?
                <Link to={`/${_.kebabCase(sec.title)}/${_.kebabCase(block.siteLink.title)}`}><InnerImageZoom src={block.image.asset.url} hideHint={true} zoomType="hover" zoomPreload={true} /></Link>
                :
                <InnerImageZoom src={block.image.asset.url} hideHint={true} />
                }
              </div>
              }
              {block.slides &&
              <div style={{ gridRow: ((block.y + 1) + "/" + (Number(block.y) + Number(block.height) + 1)), gridColumn:((block.x + 1) + "/" + (Number(block.x) + Number(block.width) + 1)) }}>
              <CarouselWrap>
              <Carousel showThumbs={false} showStatus={false} showIndicators={false} autoPlay={true} infiniteLoop={true} renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <button type="button" onClick={onClickHandler} title={label} style={{ left: 0 }}>
                            <IconContext.Provider value={{ size: '2.5em' }}><FiChevronLeft /></IconContext.Provider>
                        </button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <button type="button" onClick={onClickHandler} title={label} style={{  right: 0 }}>
                            <IconContext.Provider value={{ size: '2.5em' }}><FiChevronRight /></IconContext.Provider>
                        </button>
                    )
                }>
                {block.slides.map(slide => 
                <div>
                  <Link to={`/${_.kebabCase(sec.title)}/${_.kebabCase(slide.link.title)}`}>
                  <GatsbyImage image={slide.image.asset.gatsbyImageData} alt={slide.link.title} />
                  <Slidetitle title={slide.link.title} />
                  </Link>
                </div>
                )}
                </Carousel>
                </CarouselWrap>
                </div>
              }
              </>
            )}
            </div>
          </Anisection>
          </div>
        </section> :
        <Inquiries key={sec.id}>
          <Anisection>
          <SimpleBar style={{ maxWidth: "90vw"}}>
            <section>
            {sec.page.map(inquiry => 
                <div key={inquiry.id}><Link to={`/work/${_.kebabCase(sec.title)}/${_.kebabCase(inquiry.title)}`} asModal><div><GatsbyImage image={inquiry.ledeImage.asset.gatsbyImageData} alt="image" objectFit="conver" /><h4>{inquiry.title}</h4></div></Link></div>
            )}
            </section>
          </SimpleBar>
          </Anisection>
        </Inquiries>
        }
      </>
      )}
    </Mainwrap>
    </>
  )
}

export default IndexPage

export const Head = () => (
  <Seo pageTitle="YES!"/>
)  

