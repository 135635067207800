import React from 'react'
import styled from "@emotion/styled"

const Aphorismwrap = styled.div`
height: 100%;
width: 100%;
border: solid 1px transparent;
box-sizing: border-box;
color: rgba(255,255,255,0.33);
transition: border 0.3s ease, color 0.3s ease;
&:hover {
    border: solid 1px rgba(255,255,255,0.5);
    color: #fff;
}
`
const Title = styled.h3`
position: absolute;
font-family: 'Barlow Semi Condensed', sans-serif;
top: 50%;
left: 50%;
transform: translate(-50%,-50%) rotate(90deg);
font-weight: 600;
font-size: 2rem;
line-height: 1em;
text-align: center;
letter-spacing: 2px;
text-transform: uppercase;
&.large {
    font-size: 3rem;
}
`

const Aphorism = (props) => {
    return (
        <Aphorismwrap style={props.style}>
            <Title className={props.size}>{props.title}</Title>
        </Aphorismwrap>
    )
}

export default Aphorism